import React from "react";
import { ActivateAssessmentOrg } from "../../../components/organism";

const ActivateAssessment = () => {
  return (
    <>
      <ActivateAssessmentOrg />
    </>
  );
};

export default ActivateAssessment;
