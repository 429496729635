import React, { useRef } from "react";
import { Modal } from "../../../atoms";
import { connect } from "react-redux";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Close } from "@mui/icons-material/";

const ModalLoginRequirement = (props) => {
  const refRef = useRef();
  return (
    <>
      <Modal
        className="radius-16 postition-relative bg-danger max-w-440   modal-custom bg-white"
        style={{ top: "-70px" }}
        id={props.id}
      >
        <div
          data-bs-dismiss="modal"
          className="cursor-pointer"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <Close />
        </div>
        <div className=" p-16">
          <div className="mt-40">
            <div className="mt-16 ">
              <div className="text-center">
                <h5 className="md-h4">Please check your device</h5>
              </div>
              <p className="font-xs md-font-sm text-neutral-300 w-262 my-8">
                Minimum requirements:
              </p>
              <div className="mb-12">
                <p>Browser</p>
                <ol className="pl-16 font-sm text-neutral-300">
                  <li>Google Chrome lastest update from 2019</li>
                  <li>Mozilla firefox latest update from 2019</li>
                  <li>Safari latest update from 2019</li>
                </ol>
              </div>
              <div className="mb-12">
                <p>Operating System</p>
                <ol className="pl-16 font-sm text-neutral-300">
                  <li>Minimum Windows 8, we recommend Windows 10 or latest</li>
                  <li>Minimum MacOS Catalina Version (10.15.7) 2019</li>
                  <li>Minimum Android 10.0 2019</li>
                  <li>Minimum iOS 13 / iPadOS 13 2019</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect()(ModalLoginRequirement);
