import React, { useState } from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { defConfig, GET, POST } from "../../config/RestAPI";

function useTeacherResourceWorksheet() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [filter, setFilter] = useState([
    "Junior High School",
    "Elementary",
    "Senior High School",
    "General",
  ]);
  const [cefr, setCefr] = useState([
    "Pre A1",
    "A1",
    "A2",
    "B1",
    "B2",
    "C1",
    "C2",
  ]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");

  const [initialValues, setInitialValues] = useState({
    general: true,
    elementarySchool: true,
    juniorHighSchool: true,
    seniorHighSchool: true,
    preA1: true,
    A1: true,
    A2: true,
    B1: true,
    B2: true,
    C1: true,
    C2: true,
    showing: "10",
  });

  const [pagination, setPagination] = useState({
    current_page: 0,
    per_page: 10,
    next_page: false,
    prev_page: false,
    total: 0,
    total_page: 0,
  });

  const onFilter = (values) => {
    setInitialValues(values);
    let newFilter = [];
    let newCefr = [];
    if (values.general) {
      newFilter.push("General");
    }
    if (values.elementarySchool) {
      newFilter.push("Elementary");
    }
    if (values.juniorHighSchool) {
      newFilter.push("Junior High School");
    }
    if (values.seniorHighSchool) {
      newFilter.push("Senior High School");
    }
    if (values.preA1) {
      newCefr.push("Pre A1");
    }
    if (values.A1) {
      newCefr.push("A1");
    }
    if (values.A2) {
      newCefr.push("A2");
    }
    if (values.B1) {
      newCefr.push("B1");
    }
    if (values.B2) {
      newCefr.push("B2");
    }
    if (values.C1) {
      newCefr.push("C1");
    }
    if (values.C2) {
      newCefr.push("C2");
    }

    if (newFilter.length > 0) {
      setFilter(newFilter);
      setPerPage(parseInt(values.showing));
      initData(1, parseInt(values.showing), keyword, newFilter, newCefr);
    }
    if (newCefr.length > 0) {
      setCefr(newCefr);
      setPerPage(parseInt(values.showing));
      initData(1, parseInt(values.showing), keyword, newFilter, newCefr);
    } else {
      setData([]);
      setPagination({
        current_page: 0,
        per_page: 10,
        next_page: false,
        prev_page: false,
        total: 0,
        total_page: 0,
      });
    }
  };

  const resetFilter = () => {
    setInitialValues({
      general: true,
      elementarySchool: true,
      juniorHighSchool: true,
      seniorHighSchool: true,
      preA1: true,
      A1: true,
      A2: true,
      B1: true,
      B2: true,
      C1: true,
      C2: true,
      showing: "10",
    });
  };

  const formik = useFormik({
    initialValues: { keyword: "" },
    onSubmit: (values) => {
      if (filter.length > 0) {
        setKeyword(values.keyword);
        initData(page, perPage, values.keyword, filter, cefr);
      }
    },
  });

  const handlePageClick = (toPage) => {
    setPage(toPage);
    initData(toPage, perPage, keyword, filter, cefr);
  };

  const initData = (
    dataPage,
    dataPerPage,
    dataKeyword,
    dataFilter,
    dataCefr
  ) => {
    setIsLoading(true);
    setData([]);
    GET(
      `/resources/worksheets?page=${dataPage}&per_page=${dataPerPage}&keyword=${dataKeyword}`,
      defConfig(dataFilter, dataCefr)
    )
      .then((res) => {
        setData(res.data);
        setPagination({
          current_page: res.pagination.current_page
            ? res.pagination.current_page
            : 0,
          per_page: res.pagination.per_page ? res.pagination.per_page : 10,
          next_page: res.pagination.next_page
            ? res.pagination.next_page
            : false,
          prev_page: res.pagination.prev_page
            ? res.pagination.prev_page
            : false,
          total: res.pagination.total ? res.pagination.total : 0,
          total_page: res.pagination.total_page ? res.pagination.total_page : 0,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("error", err.message);
      });
  };

  useEffect(() => {
    initData(page, perPage, keyword, filter, cefr);
  }, []);

  return {
    isLoading,
    data,
    initialValues,
    pagination,
    onFilter,
    resetFilter,
    formik,
    handlePageClick,
  };
}

export default useTeacherResourceWorksheet;
