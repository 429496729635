import React from "react";
import { Register3Org } from "../../../components/organism";

const Register3 = () => {
  return (
    <>
      <Register3Org />
    </>
  );
};

export default Register3;
