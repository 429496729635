import React, { useRef, useState } from "react";
import { Field } from "formik";
function InputFile(props) {
  const inputRef = useRef();

  return (
    <div className={""}>
      {props.label && <label>{props.label}</label>}
      <Field className={""} id={""} name={props.imageName}>
        {({ form, field, meta: { touched, error } }) => {
          let { setFieldValue } = form;
          return (
            <>
              <input
                type="file"
                accept={"*"}
                className="d-none"
                ref={inputRef}
                onInput={(e) => {
                  let file = e.target.files[0];
                  if (props.maxSize && file) {
                    if (file.size > props.maxSize) {
                      props.onErrorSize(true);
                    } else {
                      props.onErrorSize(false);
                    }
                  }
                  setFieldValue(props.fakeName, file);
                }}
                {...field}
              />
              <div
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                {props.component}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default InputFile;
