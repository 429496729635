import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "../../../atoms";
import CloseIcon from '@mui/icons-material/Close';
const ModalChangeProfile = (props) => {
  const { data } = useSelector((state) => state.modalData);
  const [defaultImage, setDefaultImage] = useState(
    data.avatar || "/images/profile/avatar-1.png"
  );

  return (
    <Modal
      className="radius-16 postition-relative bg-danger max-w-660   modal-custom bg-white"
      style={{ top: "-70px" }}
      id={props.id}
    >
      <div className=" py-24 px-32">
        <div className="d-flex justify-content-between align-items-top">
          <div>
            <h5 className="h6 md-h5"> Profile Avatar</h5>
            <p className="mt-4 text-neutral-300 font-sm">
              Select available avatar for your profile picture.
            </p>
          </div>
          <div  data-bs-dismiss="modal" className="clickable">
            <CloseIcon/>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-40">
          <img src={defaultImage} alt="" className="h-120 w-120 md-h-190 md-w-190 radius-p-100" />
        </div>
        <div className="mt-40">
          <div className="d-flex justify-content-center gap-28 flex-wrap">
            <img
              src="/images/profile/avatar-1.png"
              alt=""
              className="h-64 w-64 md-h-96 md-w-96 radius-p-100 clickable"
              onClick={() => setDefaultImage("/images/profile/avatar-1.png")}
            />

            <img
              src="/images/profile/avatar-2.png"
              alt=""
              className="h-64 w-64 md-h-96 md-w-96 radius-p-100 clickable"
              onClick={() => setDefaultImage("/images/profile/avatar-2.png")}
            />

            <img
              src="/images/profile/avatar-3.png"
              alt=""
              className="h-64 w-64 md-h-96 md-w-96 radius-p-100 clickable"
              onClick={() => setDefaultImage("/images/profile/avatar-3.png")}
            />

            <img
              src="/images/profile/avatar-4.png"
              alt=""
              className="h-64 w-64 md-h-96 md-w-96 radius-p-100 clickable"
              onClick={() => setDefaultImage("/images/profile/avatar-4.png")}
            />

            <img
              src="/images/profile/avatar-5.png"
              alt=""
              className="h-64 w-64 md-h-96 md-w-96 radius-p-100 clickable"
              onClick={() => setDefaultImage("/images/profile/avatar-5.png")}
            />
          </div>
        </div>
        <div className="mt-40 d-flex justify-content-center justify-content-md-end gap-24 w-full">
          <button
            className="btn btn-outline px-40 "
            type="button"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            data-bs-dismiss="modal"
            className="btn btn-primary px-40"
            type="button"
            onClick={() => {
              props.onSave(defaultImage);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalChangeProfile;
