import React from "react";
import BookListDashboardCard from "../../Cards/BookListDashboardCard";
import MyBookListContentLoading from "../../SkeletonLoading/MyBookListLoading/MyBookListContentLoading";
import { Link } from "react-router-dom";
import NoBookImage from "../../../../assets/images/search-no-data.png";
const MyBookListStudentDashboard = (props) => {
  const { data, isLoading } = props;
  return (
    <div className="w-full h-full radius-14 border border-2 border-secondary-500">
      <div className="py-24 px-32">
        <h6 className="md-h5 mb-16">My Book List</h6>
        {isLoading ? (
          <MyBookListContentLoading />
        ) : (
          <div className=" d-flex " style={{ gap: "16px", flexWrap: "wrap" }}>
            {data.length < 1 ? (
              <NoBook />
            ) : (
              data.map((r, i) => {
                return (
                  <div className="" key={r._id + "-" + i}>
                    <Link
                      to={`/classroom/start-learning-view/${r.classlist}/${r.subject._id}`}
                    >
                      <BookListDashboardCard
                        data={r.subject}
                        progress={r.progress}
                        className="card-book-xl "
                      />
                    </Link>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const NoBook = () => {
  return (
    <>
      <div className="w-full d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img
            src={NoBookImage}
            alt=""
            className="h-full w-full max-h-104 max-w-104 md-max-h-143 md-max-w-143"
          />
          <p className="font-sm-bold mt-24 sm-h6">No Book Yet</p>
          <p className="font-xs-medium mt-8 md-font-sm-medium text-neutral-400">
            Make sure your teacher have assign you to any book.
          </p>
        </div>
      </div>
    </>
  );
};
export default MyBookListStudentDashboard;
