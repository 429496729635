import React from "react";
import bgEscalate from "../assets/images/bg.png";
import bgEscalate2 from "../assets/images/bg2.png";

import bgPlay from "../assets/images/bg-play1.png";
import bgPlay2 from "../assets/images/bg-play2.png";

import signImage from "../assets/images/sign.png";
import vectorMainImage from "../assets/images/vector-main.png";
import academicImage from "../assets/images/academic.png";
import {
  Document,
  Page,
  View,
  StyleSheet,
  PDFViewer,
  Text,
  PDFDownloadLink,
  Font,
  Image,
} from "@react-pdf/renderer";
import style from "../style";

const SerificateDesign = ({ data }) => {
  return (
    <>
      <Document>
        <Page size="A4" orientation="landscape">
          <View>
            <Image
              src={data.level === "Play" ? bgPlay : bgEscalate}
              style={{
                position: "absolute",
                top: "-2px",
                left: "0px",
              }}
            />

            <View style={{ marginLeft: "178px" }}>
              <Text style={[style.masterH, { marginTop: "50px" }]}>
                CERTIFICATE OF
              </Text>
              <Text style={[style.masterH]}>COMPLETION</Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        marginTop: "43px",
                        color: "#7F8894",
                        fontWeight: 500,
                        fontSize: "16px",
                      },
                    ]}
                  >
                    This certificate is presented to:
                  </Text>

                  <Text
                    style={[
                      style.h2,
                      {
                        marginTop: "4px",
                        color: "#397CAB",
                        fontSize: "24px",
                        fontWeight: 700,
                      },
                    ]}
                  >
                    {data.name}
                  </Text>

                  <Text
                    style={[
                      style.fontNunito,
                      { fontSize: "16px", marginTop: "4px", color: "#7F8894" },
                    ]}
                  >
                    from
                  </Text>
                  <Text
                    style={[style.h2, { marginTop: "4px", color: "#397CAB" }]}
                  >
                    Atlaz School
                  </Text>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#7F8894",
                        width: "320px",
                        fontWeight: 500,
                      },
                    ]}
                  >
                    Has successfully completed immersive English learning at
                    Atlaz Academy with
                  </Text>
                  <Text
                    style={[
                      style.pText,
                      {
                        marginTop: "24px",
                        fontFamily: "Ubuntu",

                        textAlign: "center",
                        paddingVertical: "4px",

                        color: "#2A384C",
                        width: "190px",
                        fontWeight: 700,
                        border: "2px solid #FDBF48",
                        borderRadius: "5px",
                        backgroundColor: "#FEE5B5",
                      },
                    ]}
                  >
                    {data.subject}
                  </Text>

                  <Text
                    style={[
                      style.fontUbuntu,
                      {
                        fontWeight: 500,
                        color: "#2B5D80",
                        fontSize: "12px",
                        marginTop: "44px",
                      },
                    ]}
                  >
                    {data.date}
                  </Text>
                  <Image
                    style={{ width: "117px", height: "auto" }}
                    src={signImage}
                  />
                  <Text
                    style={[
                      style.fontUbuntu,
                      {
                        fontWeight: 500,
                        color: "#2B5D80",
                        fontSize: "12px",
                        marginTop: "4px",
                      },
                    ]}
                  >
                    Tommy Yonathan Kumala
                  </Text>
                  <Text
                    style={[
                      style.ptext,
                      style.italic,
                      {
                        color: "#7F8894",
                        fontSize: "8px",
                        fontWeight: "600",
                      },
                    ]}
                  >
                    CEO and Founder of Atlaz
                  </Text>
                </View>

                <View style={{ flex: 1, position: "relative", width: "100%" }}>
                  <View
                    style={{ position: "absolute", top: "76px", width: "96%" }}
                  >
                    <Text
                      style={[
                        style.ptext,
                        {
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: 700,
                          color: "white",
                        },
                      ]}
                    >
                      Average Score
                    </Text>
                    <Text
                      style={[
                        style.h1,
                        {
                          textAlign: "center",
                          fontSize: "48px",
                          fontWeight: 500,
                          color: "white",
                        },
                      ]}
                    >
                      {data.avgGrade}
                    </Text>
                    <Text
                      style={[
                        style.h1,
                        {
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: 500,
                          marginTop: "8px",
                          color: "white",
                        },
                      ]}
                    >
                      CEFR {data.cefrLevel}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="landscape">
          <View>
            <Image
              src={data.level === "Play" ? bgPlay2 : bgEscalate2}
              style={{
                position: "absolute",
                top: "-2px",
                left: "0px",
              }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "28px",
              }}
            >
              <Image
                src={vectorMainImage}
                style={{
                  width: "20px",
                  height: "auto",
                }}
              />
            </View>

            <Text
              style={[
                style.fontUbuntu,
                {
                  color: "#FDFDFE",
                  fontSize: "24px",
                  fontWeight: 500,
                  textAlign: "center",
                  marginTop: "8px",
                },
              ]}
            >
              Academic Transcript
            </Text>
          </View>
          {/* section 1 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <View
              style={{
                width: "602px",
                paddingVertical: "16px",
                paddingHorizontal: "24px",
                backgroundColor: "#FDFDFE",
                borderRadius: "8px",
              }}
            >
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ flex: "1" }}>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#7F8894",
                      },
                    ]}
                  >
                    Name
                  </Text>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#2A384C",
                      },
                    ]}
                  >
                    {data.name}
                  </Text>
                </View>
                <View style={{ flex: "1" }}>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#7F8894",
                      },
                    ]}
                  >
                    School
                  </Text>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#2A384C",
                      },
                    ]}
                  >
                    {data.school}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "16px",
                }}
              >
                <View style={{ flex: "1" }}>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#7F8894",
                      },
                    ]}
                  >
                    Subject
                  </Text>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#2A384C",
                      },
                    ]}
                  >
                    {data.subject}
                  </Text>
                </View>
                <View style={{ flex: "1" }}>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#7F8894",
                      },
                    ]}
                  >
                    CEFR level
                  </Text>
                  <Text
                    style={[
                      style.fontNunito,
                      {
                        fontSize: "12px",
                        color: "#2A384C",
                      },
                    ]}
                  >
                    {data.cefrLevel}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* section 2 */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <View
              style={{
                width: "602px",
                paddingVertical: "16px",
                paddingHorizontal: "24px",
                backgroundColor: "#FDFDFE",
                borderRadius: "8px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={[
                    style.fontNunito,
                    {
                      fontSize: "12px",
                      color: "#7F8894",
                    },
                  ]}
                >
                  Lesson
                </Text>
                <Text
                  style={[
                    style.fontNunito,
                    {
                      fontSize: "12px",
                      color: "#7F8894",
                    },
                  ]}
                >
                  Avg. Grade
                </Text>
              </View>

              {data?.lesson?.map((r, i) => {
                return (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "16px",
                    }}
                    key={"lesson-sert-" + i}
                  >
                    <Text
                      style={[
                        style.fontNunito,
                        {
                          fontSize: "12px",
                          color: "#2A384C",
                        },
                      ]}
                    >
                      {r.name}
                    </Text>
                    <Text
                      style={[
                        style.fontNunito,
                        {
                          fontSize: "12px",
                          color: "#2A384C",
                          width: "61px",
                          textAlign: "center",
                        },
                      ]}
                    >
                      {r.avg || 0}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
          {/* section 3 */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <View
              style={{
                width: "602px",
                paddingVertical: "16px",
                paddingHorizontal: "24px",
                backgroundColor: "#FDFDFE",
                borderRadius: "8px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={[
                    style.fontNunito,
                    {
                      fontSize: "12px",
                      color: "#2A384C",
                      fontWeight: 600,
                    },
                  ]}
                >
                  Total Grade
                </Text>
                <Text
                  style={[
                    style.fontNunito,
                    {
                      fontSize: "12px",
                      color: "#2A384C",
                      width: "61px",
                      textAlign: "center",
                      fontWeight: 600,
                    },
                  ]}
                >
                  {data.totalGrade}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <Text
                  style={[
                    style.fontNunito,
                    {
                      fontSize: "14px",
                      color: "#2A384C",
                      fontWeight: 700,
                    },
                  ]}
                >
                  Average Grade
                </Text>

                <Text
                  style={[
                    style.fontNunito,
                    {
                      fontSize: "14px",
                      color: "#2A384C",
                      width: "61px",
                      textAlign: "center",
                      fontWeight: 700,
                    },
                  ]}
                >
                  {data.avgGrade}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default SerificateDesign;
