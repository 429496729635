import React from "react";
import { ErrorViewOrg } from "../../components/organism";

const ErrorView = () => {
  return (
    <>
      <ErrorViewOrg />
    </>
  );
};

export default ErrorView;
