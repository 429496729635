import React from "react";
import { BookListPracticeOrg } from "../../../components/organism";
const BookListPractice = () => {
  return (
    <>
      <BookListPracticeOrg />
    </>
  );
};

export default BookListPractice;
