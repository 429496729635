import React from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  PDFViewer,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import DataDynamic from "./DataDynamic";

const style = StyleSheet.create({
  page: {
    width: "100%",
    height: "auto",
  },
});

export const MyDoc = ({ data }) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={style.page}>
        <DataDynamic data={data} />
      </Page>
    </Document>
  );
};

const Certificate = ({ data }) => {
  return (
    <div style={{ flexGrow: 1 }}>
      <PDFViewer
        showToolbar={false}
        style={{
          width: "100%",
          height: "95%",
        }}
      >
        <MyDoc />
      </PDFViewer>
    </div>
  );
};
export default Certificate;
