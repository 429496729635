import React from "react";
import { VerifyProfileOrg } from "../../../components/organism";

const VerifyProfile = () => {
  return (
    <>
      <VerifyProfileOrg />
    </>
  );
};

export default VerifyProfile;
