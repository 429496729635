import React from "react";
import { VerifyOrg } from "../../../components/organism";

const Verify = () => {
  return (
    <>
      <VerifyOrg />
    </>
  );
};

export default Verify;
