import React from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  PDFViewer,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import SerificateDesign from "../components/organism/Certificate/assessment/SerificateDesign";

const TestPDF = () => {
  return (
    <>
      <PDFViewer
        showToolbar={false}
        style={{
          width: "100%",
          height: "800px",
        }}
      >
        <SerificateDesign />
      </PDFViewer>

      <PDFDownloadLink
        document={<SerificateDesign />}
        fileName={`tesasdasd.pdf`}
        style={{ borderRadius: "8px" }}
      >
        <button>download</button>
      </PDFDownloadLink>
    </>
  );
};

export default TestPDF;
