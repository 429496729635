import React, { useEffect, useState } from "react";
import GradeCard from "../../Cards/GradeCard";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { FormikControl, TableThead } from "../../../atoms";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useTeacherGradeDetail, useExportExcel } from "../../../../services";
import * as Icons from "@material-ui/icons";
import {
  Search,
  TableChart,
  Launch,
  ArrowCircleDown,
  ArrowBack,
  ArrowForward,
  School,
} from "@mui/icons-material";
import "../Table.scss";

function TableTeacherGradeDetail() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    isLoading,
    isLoadingTable,
    dataHeader,
    sortirHeader,
    initialValuesTableOption,
    tableOption,
    setTableOption,
    onSubmitTableOption,
    dataGradeDetail,
    handlePageClick,
    formik,
    onSubmit,
    onReset,
    pagination,
    dataExcel,
    csvDataName,
    avg,
  } = useTeacherGradeDetail();
  const { exportToExcel } = useExportExcel();

  return (
    <>
      {!isLoading ? (
        <>
          <div className="cards-grade-all mb-24">
            <div className="row">
              <GradeCard tableOption={tableOption} />
              <div className={`col-md-${12 / (tableOption.length + 1)}`}>
                <div
                  className="d-flex radius-8 bg-neutral-50 py-16 px-24 mb-8"
                  style={{ border: "2px solid #E1E9F3" }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center w-48 h-48 mr-16 radius-8"
                    style={{
                      backgroundColor: '#F3FDE8',
                      color: '#A8DF8E',
                    }}
                  >
                    {React.createElement(Icons['Grade'])}
                  </div>
                  <div className="detail">
                    <div className="name font-sm text-neutral-300">
                      Avg. Grade
                    </div>
                    <div className="desc font-normal">{avg?.toFixed(1) || '0.0'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="top-table h-auto p-3 bg-secondary-300">
            <Formik
              initialValues={initialValuesTableOption}
              onSubmit={onSubmitTableOption}
              enableReinitialize={true}
            >
              <div className="w-full">
                <Form className="d-flex justify-content-between gap-md-5 flex-column flex-md-row">
                  <div className="d-flex gap-2 w-full flex-column flex-md-row">
                    <div className="w-100">
                      <FormikControl
                        size="xs"
                        control="input"
                        name="keyword_student"
                        placeholder="Search student here"
                        icon={<Search className="text-neutral-200 fs-16" />}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-md-end">
                    <button
                      type="submit"
                      className="btn btn-outline border-1 p-[4px] bg-warning font-sm text-neutral-500 mr-16"
                    >
                      Apply
                    </button>
                    <button
                      type="reset"
                      className="btn btn-outline font-sm text-neutral-500 mr-16"
                      onClick={() => onReset()}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-outline bg-white fs-14 text-neutral-500 d-flex"
                      onClick={() =>
                        exportToExcel([dataExcel], csvDataName, "Grade-Oveview-Student")
                      }
                    >
                      <ArrowCircleDown className="text-neutral-500 fs-18 mr-6" />{" "}
                      Export Table
                    </button>
                  </div>
                </Form>
              </div>
            </Formik>
          </div>
          <div className="show-datatable">
            <div className="table-responsive">
              <table className="table-report">
                <thead>
                  <tr>
                    {dataHeader.map((item, index) => {
                      if (item.status) {
                        return (
                          <th key={index} width={item.width}>
                            <TableThead
                              title={item.title}
                              placeholder={item.placeholder}
                              sortir={item.sortir}
                              isSorted={item.isSorted}
                              isSortedDesc={item.isSortedDesc}
                              onClick={() => {
                                sortirHeader(index);
                              }}
                              onInput={(e) => {
                                console.log(e.target.value);
                              }}
                            />
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dataGradeDetail ? (
                    dataGradeDetail?.results?.length > 0 ? (
                      dataGradeDetail?.results?.map((item, index) => {
                        // let progress = ''
                        // if(item.done > 0 && item.done === item.count) {
                        //   progress = 'completed'
                        // } else if(item.done > 0 && item.done < item.count) {
                        //   progress = 'ongoing'
                        // } else {
                        //   progress = 'incompleted'
                        // }
                        const nameStudent = item.student?.name
                          .toLowerCase()
                          .split(" ");
                        for (let i = 0; i < nameStudent.length; i++) {
                          nameStudent[i] =
                            nameStudent[i][0].toUpperCase() +
                            nameStudent[i].substr(1) +
                            " ";
                        }
                        nameStudent.join(" ");
                        return (
                          <tr key={index}>
                            {item.classlist && (
                              <td>
                                {item.classlist?.name} -{" "}
                                {item.classlist?.academicYear}
                              </td>
                            )}
                            {item.subject && <td>{item.subject?.name}</td>}
                            <td>{nameStudent}</td>
                            {/* <td width="20%">
                                  <div className={`font-xs-medium radius-4 px-8 py-2 d-inline nowrap ${progress}`}>{item.done} / {item.count} Completed</div>
                                </td> */}
                            {item.subject && !item.classlist ? (
                              <td>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/grade/grade-overview/detail/${item.subject._id}/${dataGradeDetail.classlist._id}/score/${item.student._id}`,
                                      {
                                        state: `/grade/grade-overview/detail/class/${dataGradeDetail.classlist._id}`,
                                      }
                                    )
                                  }
                                >
                                  {parseFloat(item.score_subject).toFixed(1)
                                    ? parseFloat(item.score_subject).toFixed(1)
                                    : "N/A"}{" "}
                                  <Launch className="text-neutral-100 fs-18 ml-6" />
                                </div>
                              </td>
                            ) : !item.subject && item.classlist ? (
                              <td>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/grade/grade-overview/detail/${dataGradeDetail.subject._id}/${item.classlist._id}/score/${item.student._id}`,
                                      {
                                        state: `/grade/grade-overview/detail/subject/${dataGradeDetail.subject._id}`,
                                      }
                                    )
                                  }
                                >
                                  {parseFloat(item.score_subject).toFixed(1)
                                    ? parseFloat(item.score_subject).toFixed(1)
                                    : "N/A"}{" "}
                                  <Launch className="text-neutral-100 fs-18 ml-6" />
                                </div>
                              </td>
                            ) : (
                              <td>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/grade/grade-overview/detail/${dataGradeDetail.subject._id}/${dataGradeDetail.classlist._id}/score/${item.student._id}`,
                                      {
                                        state: `/grade/grade-overview/detail/${dataGradeDetail.subject._id}/${dataGradeDetail.classlist._id}`,
                                      }
                                    )
                                  }
                                >
                                  {parseFloat(item.score_subject).toFixed(1)
                                    ? parseFloat(item.score_subject).toFixed(1)
                                    : "N/A"}{" "}
                                  <Launch className="text-neutral-100 fs-18 ml-6" />
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="datanotfound text-center font-sm text-neutral-200"
                          colSpan="5"
                        >
                          {isLoadingTable ? (
                            <>Loading data...</>
                          ) : (
                            <>No data available</>
                          )}
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td
                        className="datanotfound text-center font-sm text-neutral-200"
                        colSpan="5"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="navigation-table">
              <div className="font-sm text-neutral-300 d-none d-md-block">
                {pagination.current_page} of {pagination.total_page}
              </div>
              <div className="pagination-table">
                <div className="font-sm text-neutral-300 your-page d-flex align-items-center">
                  You're in page
                  <form onSubmit={formik.handleSubmit}>
                    <div className="w-48 ml-16">
                      <div className="form-input text-start">
                        <div className="input-area h-32 font-xs bg-white">
                          <input
                            type="number"
                            id="topage"
                            name="topage"
                            className="w-full input-control radius-8 py-8 pl-16 pr-16 font-xs"
                            onChange={formik.handleChange}
                            value={formik.values.topage}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="divider-nav"></div>
                <div style={{ marginRight: "24px" }}>
                  {pagination.prev_page ? (
                    <button
                      className="btn-paginate"
                      onClick={() =>
                        handlePageClick(pagination.current_page - 1)
                      }
                    >
                      <ArrowBack style={{ fontSize: "16px" }} />
                    </button>
                  ) : (
                    <button className="btn-paginate btn-disable" disabled>
                      <ArrowBack style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
                <div>
                  {pagination.next_page ? (
                    <button
                      className="btn-paginate"
                      onClick={() =>
                        handlePageClick(pagination.current_page + 1)
                      }
                    >
                      <ArrowForward style={{ fontSize: "16px" }} />
                    </button>
                  ) : (
                    <button className="btn-paginate btn-disable" disabled>
                      <ArrowForward style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Skeleton count={2} height={50} />
      )}
    </>
  );
}

export default TableTeacherGradeDetail;
