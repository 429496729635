import React from "react";
import ErrorDefault from "./ErrorDefault";

const ErrorViewOrg = () => {
  return (
    <>
      <ErrorDefault />
    </>
  );
};

export default ErrorViewOrg;
