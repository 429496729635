import React from "react";
import { PurchasedHistoryOrg } from "../../../components/organism";
const PurchasedHistory = () => {
  return (
    <>
      <PurchasedHistoryOrg />
    </>
  );
};

export default PurchasedHistory;
