import React from "react";
import { SearchResultOrg } from "../../components/organism";

const SearchResult = () => {
  const script = document.createElement("script");
  script.src = "/js/facebook-pixel.js";
  script.async = true;

  document.body.appendChild(script);

  return (
    <>
      <SearchResultOrg />
    </>
  );
};

export default SearchResult;
