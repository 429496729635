import React, { useEffect } from "react";
import MainFooter from "../../../components/Layout/Mainlayout/MainFooter";
import { useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { encode, decode } from "js-base64";
import moment from "moment";
const ConnectiongLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // ambil refreshToken dilocal storage untuk pengkodisian apabila expired atau tidak punya refreshToken
    let refreshToken = localStorage.getItem(
      process.env.REACT_APP_BASE_URL + "/refreshToken"
    );
    // ambil parameter redirect untuk redirect kalo berhasil
    const redirectToLink = searchParams.get("redirect");
    if (!redirectToLink) {
      navigate("/page-not-found");
    } else {
      const redirectToLinkDecode = decode(redirectToLink);
      // check apakah ada refreshtToken
      if (refreshToken) {
        let decodedToken = null;
        try {
          // check apakah refreshtToken valid
          decodedToken = jwt_decode(refreshToken);
        } catch (error) {
          // kalo refreshToken gabisa di decode maka redirect ke halaman login
          navigate(`/login?redirect=${redirectToLink}`);
        }
        // jika refreshToken valid maka check apakah refreshToken belum expired
        if (moment().unix() * 1000 < decodedToken.exp * 1000) {
          // ===========LOGIN BERHASIL===============
          // jika refreshToken belum expired login berhasil dan redirect ke halaman tujuan (redirect params)
          window.location.href =
            redirectToLinkDecode + "/login?key=" + refreshToken;
        } else {
          // jika refreshToken  expired maka delete refreshToken dan accessToken
          localStorage.removeItem(
            process.env.REACT_APP_BASE_URL + "/accessToken"
          );
          localStorage.removeItem(
            process.env.REACT_APP_BASE_URL + "/refreshToken"
          );
          localStorage.removeItem(
            process.env.REACT_APP_BASE_URL + "/deviceToken"
          );
          // redirect ke halaman login dengan membawa parameter redirect ke halaman login dengan format base64
          navigate(`/login?redirect=${redirectToLink}`);
        }
      } else {
        // jika refreshToken tidak ada maka redirect ke halaman login dengan membawa parameter redirect ke halaman login dengan format base64
        navigate(`/login?redirect=${redirectToLink}`);
      }
    }
  }, []);
  return (
    <div
      className="d-flex   "
      style={{
        minHeight: "100vh",
        justifyContent: "space-between",
        flexDirection: "column",
        backgroundImage: "url('/images/bg-redirect.png')",
      }}
    >
      <div className="hidden md:block"></div>
      <div className="d-flex  flex-column align-items-center justify-content-center">
        <div
          style={{
            width: "168px",
            height: "200px",
            flexDirection: "column",
          }}
          className=" d-flex  align-items-center justify-content-center position-relative "
        >
          <div
            style={{ top: "55px" }}
            className="position-absolute   w-full  d-flex justify-content-center "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="57"
              viewBox="0 0 56 57"
              fill="none"
            >
              <path
                d="M55.5362 28.056C55.566 20.6558 52.6592 13.546 47.4535 8.28626C42.2478 3.02656 35.1683 0.0465491 27.7682 0C20.3681 0.0465491 13.2886 3.02656 8.0829 8.28626C2.8772 13.546 -0.0296103 20.6558 0.000196471 28.056C-0.00395145 33.336 1.47982 38.5104 4.28141 42.9858C7.083 47.4613 11.089 51.0567 15.8402 53.36C18.0987 54.444 20.4939 55.2164 22.9602 55.656C23.5442 55.76 24.1202 55.864 24.7122 55.928L25.3202 55.984C26.1202 56.056 26.9202 56.104 27.7682 56.104C28.5682 56.104 29.3682 56.056 30.2162 55.984L30.8242 55.928C31.4162 55.864 32.0002 55.76 32.5762 55.656C35.0296 55.1831 37.4065 54.3755 39.6402 53.256L39.6962 53.312C40.9832 54.6146 42.6277 55.5061 44.4216 55.8739C46.2155 56.2416 48.0781 56.069 49.7738 55.3778C51.4696 54.6867 52.9222 53.5081 53.9479 51.9912C54.9736 50.4742 55.5264 48.6871 55.5362 46.856V28.056ZM27.7682 46.856C25.3121 46.8544 22.8816 46.3577 20.6218 45.3955C18.3621 44.4333 16.3195 43.0254 14.6162 41.256C11.1873 37.7358 9.2647 33.0182 9.2562 28.104C9.2562 17.832 17.5442 9.256 27.7682 9.256C37.9922 9.256 46.2802 17.832 46.2802 28.056V46.856L40.9202 41.256C39.2169 43.0254 37.1743 44.4333 34.9145 45.3955C32.6548 46.3577 30.2243 46.8544 27.7682 46.856Z"
                fill="#FFCB05"
              />
            </svg>
          </div>

          <div
            role="status"
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <svg
              aria-hidden="true"
              style={{ width: "120px", height: "120px" }}
              className=" text-gray-200 animate-spin dark:text-gray-600 fill-yellow-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E1E9F3"
              />

              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#FFCB05"
              />
            </svg>
            <span
              className="sr-only "
              style={{ marginTop: "8px", fontWeight: 600 }}
            >
              Loading...
            </span>
          </div>
        </div>
        <div>
          <h3 className="third-heading">We are redirecting you</h3>
          <p className="mt-2 text-neutral-300 text-center">
            Get ready to learn with Atlaz
          </p>
        </div>
      </div>
      <div>
        <MainFooter className="" />
      </div>
    </div>
  );
};

export default ConnectiongLogin;
