import React from "react";
import { LearningSubjectViewOrg } from "../../../components/organism";
const LearningSubjectView = () => {
  return (
    <>
      <LearningSubjectViewOrg />
    </>
  );
};

export default LearningSubjectView;
