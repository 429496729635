import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { defConfig, GET, POST } from "../../config/RestAPI";

function useTeacherGradeOverview() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [dataHeader, setDataHeader] = useState([
    {
      title: "Class",
      rowName: "classlist",
      width: "43%",
      placeholder: "",
      search: "",
      sortir: true,
      isSorted: false,
      isSortedDesc: false,
      status: true,
    },
    {
      title: "Subject",
      rowName: "subject",
      width: "43%",
      placeholder: "",
      search: "",
      sortir: true,
      isSorted: false,
      isSortedDesc: false,
      status: true,
    },
    {
      title: "Action",
      rowName: "score_subject",
      width: "14%",
      placeholder: "",
      search: "",
      sortir: true,
      isSorted: false,
      isSortedDesc: false,
      status: true,
    },
  ]);

  const sortirHeader = (index) => {
    let newHeader = [];
    dataHeader.map((item, key1) => {
      if (key1 === index) {
        if (!item.isSorted && !item.isSortedDesc) {
          let data = {
            ...item,
            isSorted: true,
            isSortedDesc: true,
          };
          newHeader.push(data);
          setSortType("ASC");
          setSortBy(item.rowName);
          initData(
            page,
            perPage,
            "ASC",
            item.rowName,
            showRow,
            initialValuesTableOption.keyword_subject,
            initialValuesTableOption.keyword_class
          );
        }
        if (item.isSorted && item.isSortedDesc) {
          let data = {
            ...item,
            isSorted: true,
            isSortedDesc: false,
          };
          newHeader.push(data);
          setSortType("DESC");
          setSortBy(item.rowName);
          initData(
            page,
            perPage,
            "DESC",
            item.rowName,
            showRow,
            initialValuesTableOption.keyword_subject,
            initialValuesTableOption.keyword_class
          );
        }
        if (item.isSorted && !item.isSortedDesc) {
          let data = {
            ...item,
            isSorted: false,
            isSortedDesc: false,
          };
          newHeader.push(data);
          setSortType("DESC");
          setSortBy("score_subject");
          initData(
            page,
            perPage,
            "DESC",
            "score_subject",
            showRow,
            initialValuesTableOption.keyword_subject,
            initialValuesTableOption.keyword_class
          );
        }
      } else {
        let data = {
          ...item,
          isSorted: false,
          isSortedDesc: false,
        };
        newHeader.push(data);
      }
    });
    setDataHeader(newHeader);
  };

  const [initialValuesTableOption, setInitialValuesTableOption] = useState({
    selectClass: true,
    selectSubject: true,
    keyword_class: "",
    keyword_subject: "",
    showing: "10",
  });

  const setTableOption = (
    className = "Data Class",
    subjectName = "Data Subject"
  ) => {
    const dataTables = [];
    const newRow = [];
    if (initialValuesTableOption.selectClass) {
      newRow.push("class");
      dataTables.push({
        name: "class",
        title: "Class",
        value: true,
        icon: "EventSeat",
        backgroundIcon: "#FAEBF2",
        colorIcon: "#ECB1CF",
        detail: className,
      });
    }
    if (initialValuesTableOption.selectSubject) {
      newRow.push("subject");
      dataTables.push({
        name: "subject",
        title: "Subject",
        value: true,
        icon: "MenuBook",
        backgroundIcon: "#FFF8E6",
        colorIcon: "#FDC228",
        detail: subjectName,
      });
    }
    setShowRow(newRow);
    initData(
      page,
      perPage,
      sortType,
      sortBy,
      newRow,
      initialValuesTableOption.keyword_subject,
      initialValuesTableOption.keyword_class
    );
    localStorage.setItem(
      "GRADE_TABLE_OPTION",
      JSON.stringify(initialValuesTableOption)
    );
    localStorage.setItem(
      "GRADE_DETAIL_TABLE_OPTION",
      JSON.stringify(dataTables)
    );
  };

  const [dataGradeOverview, setDataGradeOverview] = useState();
  const [dataExcel, setDataExcel] = useState();
  const csvDataName = ["data"];

  const [showRow, setShowRow] = useState(["subject", "class"]);
  let [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortType, setSortType] = useState("ASC");
  const [sortBy, setSortBy] = useState("classlist");

  const [pagination, setPagination] = useState({
    current_page: 0,
    per_page: 10,
    next_page: false,
    prev_page: false,
    total: 0,
    total_page: 0,
  });

  const formik = useFormik({
    initialValues: { topage: 0 },
    onSubmit: (values) => {
      if (
        parseInt(values.topage) > 0 &&
        parseInt(values.topage) <= pagination.total_page
      ) {
        setPage(parseInt(values.topage));
        initData(
          parseInt(values.topage),
          perPage,
          sortType,
          sortBy,
          showRow,
          initialValuesTableOption.keyword_subject,
          initialValuesTableOption.keyword_class
        );
      }
    },
  });

  const handlePageClick = (toPage) => {
    setPage(toPage);
    formik.setFieldValue("topage", toPage);
    initData(
      toPage,
      perPage,
      sortType,
      sortBy,
      showRow,
      initialValuesTableOption.keyword_subject,
      initialValuesTableOption.keyword_class
    );
  };

  const onSubmit = () => {
    console.log("first");
  };

  const onReset = () => {
    initData(
      page,
      perPage,
      sortType,
      sortBy,
      showRow,
      (initialValuesTableOption.keyword_subject = ""),
      (initialValuesTableOption.keyword_class = "")
    );
  };

  const onSubmitTableOption = (values) => {
    let newRow = [];
    if (values.selectSubject) {
      newRow.push("subject");
    }
    if (values.selectClass) {
      newRow.push("class");
    }
    if (newRow.length > 0) {
      setShowRow(newRow);
      setPerPage(parseInt(values.showing));
      initData(
        (page = 1),
        parseInt(values.showing),
        sortType,
        sortBy,
        newRow,
        values.keyword_subject,
        values.keyword_class
      );
    }

    setInitialValuesTableOption(values);
    // let newHeader = [];
    // dataHeader.map((item, index) => {
    //   if (index < 4) {
    //     let data = {
    //       ...item,
    //       status: Object.values(values)[index],
    //     };
    //     newHeader.push(data);
    //   } else {
    //     newHeader.push(item);
    //   }
    // });
    // setDataHeader(newHeader);
  };

  const initData = (
    dataPage,
    dataPerPage,
    dataSortType,
    dataSortBy,
    dataRow,
    ksubject,
    kclass
  ) => {
    setIsLoadingTable(true);
    setDataGradeOverview([]);
    GET(
      `/report/t/grade_overview?page=${dataPage}&per_page=${dataPerPage}&sort_type=${dataSortType}&sort_by=${dataSortBy}&keyword_subject=${ksubject}&keyword_class=${kclass}`,
      defConfig(dataRow)
    )
      .then((res) => {
        setDataGradeOverview(res.data);
        formik.setFieldValue(
          "topage",
          res.pagintion.current_page ? res.pagintion.current_page : 0
        );
        setPagination({
          current_page: res.pagintion.current_page
            ? res.pagintion.current_page
            : 0,
          per_page: res.pagintion.per_page ? res.pagintion.per_page : 10,
          next_page: res.pagintion.next_page ? res.pagintion.next_page : false,
          prev_page: res.pagintion.prev_page ? res.pagintion.prev_page : false,
          total: res.pagintion.total ? res.pagintion.total : 0,
          total_page: res.pagintion.total_page ? res.pagintion.total_page : 0,
        });
        setIsLoading(false);
        setIsLoadingTable(false);

        let newDataExcel = [];
        res.data?.map((item, index) => {
          if (item.classlist && item.subject) {
            newDataExcel.push({
              no: index + 1,
              class: item.classlist
                ? `${item.classlist?.name} - ${item.classlist?.academicYear}`
                : "",
              subject: item.subject ? item.subject?.name : "",
              average: item.score_subject
                ? item.score_subject.toFixed(1)
                : 0,
            });
          } else if (item.classlist && !item.subject) {
            newDataExcel.push({
              no: index + 1,
              class: item.classlist
                ? `${item.classlist?.name} - ${item.classlist?.academicYear}`
                : "",
              average: item.score_subject
                ? item.score_subject.toFixed(1)
                : 0,
            });
          } else if (!item.classlist && item.subject) {
            newDataExcel.push({
              no: index + 1,
              subject: item.subject ? item.subject?.name : "",
              average: item.score_subject
                ? item.score_subject.toFixed(1)
                : 0,
            });
          }
        });
        setDataExcel(newDataExcel);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoadingTable(false);
        console.log("error", err.message);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingTable(true);
    initData(
      page,
      perPage,
      sortType,
      sortBy,
      showRow,
      initialValuesTableOption.keyword_subject,
      initialValuesTableOption.keyword_class
    );
  }, []);

  return {
    isLoading,
    isLoadingTable,
    dataHeader,
    sortirHeader,
    initialValuesTableOption,
    setTableOption,
    onSubmitTableOption,
    dataGradeOverview,
    handlePageClick,
    initData,
    formik,
    dataExcel,
    csvDataName,
    onReset,
    onSubmit,
    page,
    perPage,
    sortType,
    sortBy,
    pagination,
  };
}

export default useTeacherGradeOverview;
