import { decode } from "js-base64";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainFooter from "../../../components/Layout/Mainlayout/MainFooter";
import { useGlobalFunction } from "../../../services";
import { GET } from "../../../config/RestAPI";

const ConnectingLogout = () => {
  const disPatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useSelector((state) => state.auth);
  
  useEffect(() => {
    localStorage.removeItem("redirect");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/accessToken");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/refreshToken");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/deviceToken");
    disPatch({ type: "SET_AUTH", value: { ...auth, isLogin: false } });
    const redirectToLink = searchParams.get("redirect");
    if (redirectToLink) {
      const redirectToLinkDecode = decode(redirectToLink);
      window.location.href = redirectToLinkDecode;
    } else {
      navigate("/");
    }
  });
  return "";
};

export default ConnectingLogout;
