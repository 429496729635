import React, { useState } from "react";
import zoey from "../../../../assets/images/zoey.png";
import Skeleton from "react-loading-skeleton";
import { Field, Form, Formik } from "formik";
import { FormikControl, TableThead } from "../../../atoms";
import { Link } from "react-router-dom";
import { useTeacherGradeOverview, useExportExcel } from "../../../../services";
import {
  Search,
  TableChart,
  Launch,
  ArrowCircleDown,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import "../Table.scss";

function TableTeacherGradeOverview() {
  const {
    isLoading,
    isLoadingTable,
    dataHeader,
    sortirHeader,
    initialValuesTableOption,
    setTableOption,
    onSubmitTableOption,
    dataGradeOverview,
    handlePageClick,
    formik,
    onSubmit,
    pagination,
    dataExcel,
    csvDataName,
    onReset,
  } = useTeacherGradeOverview();
  const { exportToExcel } = useExportExcel();

  return (
    <>
      {!isLoading ? (
        <>
          <div className="top-table h-auto  bg-secondary-300 py-16 px-24">
            <Formik
              initialValues={initialValuesTableOption}
              onSubmit={onSubmitTableOption}
              enableReinitialize={true}
            >
              <div className="w-full">
                <Form className="d-flex justify-content-between gap-md-5 flex-column flex-md-row">
                  <div className="d-flex gap-2 w-full flex-column flex-md-row">
                    <div className="w-100">
                      <FormikControl
                        size="xs"
                        control="input"
                        name="keyword_class"
                        placeholder="Search class here"
                        icon={<Search className="text-neutral-200 fs-16" />}
                      />
                    </div>
                    <div className="w-100">
                      <FormikControl
                        size="xs"
                        control="input"
                        name="keyword_subject"
                        placeholder="Search subject here"
                        icon={<Search className="text-neutral-200 fs-16" />}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-md-end">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary border-1 p-[4px] bg-warning font-sm text-neutral-500 mr-16"
                      >
                        Apply
                      </button>
                    </div>
                    <div>
                      <button
                        type="reset"
                        className="btn btn-outline bg-white font-sm text-neutral-500 mr-16"
                        onClick={() => onReset()}
                      >
                        Reset
                      </button>
                    </div>
                    {/* <div>
                      <button
                        className="btn btn-outline bg-white fs-14 text-neutral-500 d-flex"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() =>
                          exportToExcel(
                            [dataExcel],
                            csvDataName,
                            "Grade-Oveview"
                          )
                        }
                      >
                        <ArrowCircleDown className="text-neutral-500 fs-18 mr-6" />{" "}
                        Export table
                      </button>
                    </div> */}
                  </div>
                </Form>
              </div>
            </Formik>
          </div>
          {initialValuesTableOption.selectSchool ||
          initialValuesTableOption.selectTeacher ||
          initialValuesTableOption.selectClass ||
          initialValuesTableOption.selectSubject ? (
            <div className="show-datatable">
              <div className="table-responsive">
                <table className="table-report">
                  <thead>
                    <tr>
                      {dataHeader.map((item, index) => {
                        if (item.status) {
                          return (
                            <th key={index} width={item.width}>
                              <TableThead
                                title={item.title}
                                placeholder={item.placeholder}
                                sortir={item.sortir}
                                isSorted={item.isSorted}
                                isSortedDesc={item.isSortedDesc}
                                onClick={() => {
                                  sortirHeader(index);
                                }}
                                onInput={(e) => {
                                  console.log(e.target.value);
                                }}
                              />
                            </th>
                          );
                        }
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {dataGradeOverview ? (
                      dataGradeOverview.length > 0 ? (
                        dataGradeOverview?.map((item, index) => {
                          return (
                            <tr key={index}>
                              {item.classlist && (
                                <td>
                                  {item.classlist?.name} -{" "}
                                  {item.classlist?.academicYear}
                                </td>
                              )}
                              {item.subject && <td>{item.subject?.name}</td>}
                              {item.subject && item.classlist ? (
                                <td>
                                  <div className="d-flex">
                                    {/* {parseFloat(item.score_subject) >= 0
                                      ? parseFloat(item.score_subject).toFixed(
                                          1
                                        )
                                      : "N/A"}{" "} */}
                                    <Link
                                      to={`detail/${item.subject?._id}/${item.classlist?._id}`}
                                      onClick={() =>
                                        setTableOption(
                                          item.classlist?.name,
                                          item.subject?.name
                                        )
                                      }
                                    >
                                      <Launch className="text-neutral-100 fs-18 ml-6" />
                                    </Link>
                                  </div>
                                </td>
                              ) : item.subject && !item.classlist ? (
                                <td>
                                  <div className="d-flex">
                                    {/* {parseFloat(item.score_subject) >= 0
                                      ? parseFloat(item.score_subject).toFixed(
                                          1
                                        )
                                      : "N/A"}{" "} */}
                                    <Link
                                      to={`detail/subject/${item.subject?._id}`}
                                      onClick={() =>
                                        setTableOption(
                                          item.classlist?.name,
                                          item.subject?.name
                                        )
                                      }
                                    >
                                      <Launch className="text-neutral-100 fs-18 ml-6" />
                                    </Link>
                                  </div>
                                </td>
                              ) : !item.subject && item.classlist ? (
                                <td>
                                  <div className="d-flex">
                                    {/* {parseFloat(item.score_subject) >= 0
                                      ? parseFloat(item.score_subject).toFixed(
                                          1
                                        )
                                      : "N/A"}{" "} */}
                                    <Link
                                      to={`detail/class/${item.classlist?._id}`}
                                      onClick={() =>
                                        setTableOption(
                                          item.classlist?.name,
                                          item.subject?.name
                                        )
                                      }
                                    >
                                      <Launch className="text-neutral-100 fs-18 ml-6" />
                                    </Link>
                                  </div>
                                </td>
                              ) : (
                                <td></td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            className="datanotfound text-center font-sm text-neutral-200"
                            colSpan={dataHeader.length}
                          >
                            {isLoadingTable ? (
                              <>Loading data...</>
                            ) : (
                              <>No data available</>
                            )}
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td
                          className="datanotfound text-center font-sm text-neutral-200"
                          colSpan="5"
                        >
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="navigation-table">
                <div className="font-sm text-neutral-300 d-none d-md-block">
                  {pagination.current_page} of {pagination.total_page}
                </div>
                <div className="pagination-table">
                  <div className="font-sm text-neutral-300 your-page d-flex align-items-center">
                    You're in page
                    <form onSubmit={formik.handleSubmit}>
                      <div className="w-48 ml-16">
                        <div className="form-input text-start">
                          <div className="input-area h-32 font-xs bg-white">
                            <input
                              type="number"
                              id="topage"
                              name="topage"
                              className="w-full input-control radius-8 py-8 pl-16 pr-16 font-xs"
                              onChange={formik.handleChange}
                              value={formik.values.topage}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="divider-nav"></div>
                  <div style={{ marginRight: "24px" }}>
                    {pagination.prev_page ? (
                      <button
                        className="btn-paginate"
                        onClick={() =>
                          handlePageClick(pagination.current_page - 1)
                        }
                      >
                        <ArrowBack style={{ fontSize: "16px" }} />
                      </button>
                    ) : (
                      <button className="btn-paginate btn-disable" disabled>
                        <ArrowBack style={{ fontSize: "16px" }} />
                      </button>
                    )}
                  </div>
                  <div>
                    {pagination.next_page ? (
                      <button
                        className="btn-paginate"
                        onClick={() =>
                          handlePageClick(pagination.current_page + 1)
                        }
                      >
                        <ArrowForward style={{ fontSize: "16px" }} />
                      </button>
                    ) : (
                      <button className="btn-paginate btn-disable" disabled>
                        <ArrowForward style={{ fontSize: "16px" }} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <img src={zoey} alt="" className="w-174 mb-24" />
              <div className="font-normal text-neutral-500">
                Please select "Data Select" button <br />
                to display the data.
              </div>
            </div>
          )}
        </>
      ) : (
        <Skeleton count={2} height={50} />
      )}
    </>
  );
}

export default TableTeacherGradeOverview;
