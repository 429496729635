import React from "react";
import { MyBookListOrg } from "../../components/organism";

const MyBookList = () => {
  return (
    <>
      <MyBookListOrg />
    </>
  );
};

export default MyBookList;
