import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';

const BaseAPIURL = process.env.REACT_APP_BASE_API_URL;
const BaseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL: BaseAPIURL,
});

const defConfig = (params, params2) => {
  const tokenAccess = localStorage.getItem(BaseURL + "/accessToken");
  const config = {
    headers: { token: "Bearer " + tokenAccess },
    params: params && {
      column: params,
      filter: params,
      cefr: params2,
    },
  };
  return config;
};

function getLocalAccessToken() {
  const accessToken = localStorage.getItem(BaseURL + "/accessToken");
  return accessToken;
}

API.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers.token = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem(BaseURL + "/refreshToken");

    if (error?.response?.data === "Please Refresh Token!") {
      let decode = jwt_decode(refreshToken);
      const timeNow = Math.floor(new Date().getTime() / 1000);

      if(timeNow > decode.exp) {
        localStorage.removeItem("redirect");
        localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/refreshToken");
        localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/accessToken");
        localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/deviceToken");
        const navigate = useNavigate();
        navigate("/");
        // axios.get(`/auth/logout/${decode.id}`, originalRequest);
        // return <Navigate to="/login" />

      } else {
        return axios.get("/users/token", originalRequest).then((res) => {
          localStorage.setItem(
            process.env.REACT_APP_BASE_URL + "/refreshToken",
            res.data.refreshToken
          );
          localStorage.setItem(
            process.env.REACT_APP_BASE_URL + "/accessToken",
            res.data.accessToken.token
          );
          originalRequest.headers.token = "Bearer " + res.data.accessToken.token;
          return axios(originalRequest);
        });
      }
    } 
    return Promise.reject(error);
  }
);

const GET = (url, req, config) =>
  API.get(url, req, config).then((res) => res.data);

const POST = (url, data, config) =>
  API.post(url, data, config).then((res) => res.data);

const PUT = (url, data, config) =>
  API.put(url, data, config).then((res) => res.data);

const PATCH = (url, data, config) =>
  API.patch(url, data, config).then((res) => res.data);

const DESTROY = (url, data, config) =>
  API.delete(url, data, config).then((res) => res.data);

export { GET, POST, PUT, PATCH, DESTROY, defConfig, BaseAPIURL };
