import React from "react";
import { SubjectPostOrg } from "../../../components/organism";

const SubjectPost = () => {
  return (
    <>
      <SubjectPostOrg />
    </>
  );
};

export default SubjectPost;
