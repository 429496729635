import React, { useEffect } from "react";
import { HomeOrg } from "../../components/organism";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({ type: "CLEAR_AUTH_LOCAL" });
    navigate("/login");
  }, []);

  return "";
  return (
    <>
      <HomeOrg />
    </>
  );
};

export default Home;
