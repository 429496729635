import React, { useEffect, useState } from "react";
import useGlobalFunction from "../GlobalFuntions/useGlobalFunction"

function useGames() {
  const { getUserInfo } = useGlobalFunction();
  const user = getUserInfo();

  const [breadcrumbsData, setBreadcrumbsData] = useState([
    {
      link: "/dashboard",
      label: "Dashboard",
    },
    {
      link: "",
      label: "Games",
    },
  ]);

  const styleContainer = {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    paddingTop: `${(900 / 1440) * 100}%`,
  };
  const styleIframe = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
  };

  return {
    user,
    breadcrumbsData,
    styleContainer,
    styleIframe,
  };
}

export default useGames;
