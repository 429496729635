import React from "react";
import { TimelineOrg } from "../../../components/organism";

const TimelineShow = () => {
  return (
    <>
      <TimelineOrg />
    </>
  );
};

export default TimelineShow;
