import React from "react";
import { DetailBookListOrg } from "../../../components/organism";

const DetailBook = () => {
  return (
    <>
      <DetailBookListOrg />
    </>
  );
};

export default DetailBook;
