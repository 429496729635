import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGlobalFunction } from "../services";
import { POST } from "../config/RestAPI";

const Authenticate = (props) => {
  const { getUserInfo } = useGlobalFunction();
  const user = getUserInfo();
  if (!user) {
    return <Navigate to="/login" />;
  }
  const token = localStorage.getItem(process.env.REACT_APP_BASE_URL + "/deviceToken");
  POST("/auth/checkDevice", { id: user.id, token: token })
  .then((r) => {
  })
  .catch((err) => {
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/accessToken");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/refreshToken");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/deviceToken");
    return <Navigate to="/" />;
  });

  return props.children;
};

export default connect()(Authenticate);
