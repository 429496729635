import React from "react";
import { DashboardOrg } from "../../components/organism";
const Dashboard = () => {
  return (
    <>
      <DashboardOrg />
    </>
  );
};

export default Dashboard;
