import { ForgotPasswordOrg } from "../../../components/organism";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordOrg />
    </>
  );
};

export default ForgotPassword;
