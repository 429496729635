import React, { useMemo } from "react";
import {
  useClassRoomHero,
  useClassRoomHeroSubject,
  useGlobalFunction,
  useLearningSubjectView,
} from "../../../../services";
import { BreadCrumb, Divider, ProgressBar } from "../../../atoms";
import MainLayout from "../../../Layout/Mainlayout";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { LearningSubjectViewLoading, TabBar } from "../../../molecules";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDoc } from "../../Certificate";
import { Can } from "../../../../permission";
import SerificateDesign from "../../Certificate/assessment/SerificateDesign";
import moment from "moment";

const LearningSubjectViewOrg = () => {
  const {
    breadcrumbsData,
    TabBarData,
    bookDetailData,
    subjectData,
    isSubjectLoading,
    lessonData,
  } = useLearningSubjectView();
  let totalLesson = 0;
  let lessonFinish = 0;
  lessonData.map((val) => {
    totalLesson += parseInt(val.countContent ? val.countContent : 0);
    lessonFinish += parseInt(val.countPractice ? val.countPractice : 0);
  });

  const { getUserInfo } = useGlobalFunction();

  const user = getUserInfo();
  const level = bookDetailData.name?.split(" ");
  const date = new Date();
  const dataCertif = useMemo(() => {
    const result = {
      name: subjectData?.certificated?.student?.name,
      subject: subjectData?.certificated?.subject?.name,
      school: subjectData?.certificated?.school?.name,
      cefrLevel: subjectData?.cefrLevel,
      level: level ? level[1] : "Play",
      lesson: subjectData?.certificated?.lesson,
      avgGrade: subjectData?.certificated?.avgGrade,
      totalGrade: subjectData?.certificated?.totalGrade,
      date: moment(date).format("DD MMMM YYYY"),
    };
    return result;
  }, [subjectData]);

  return (
    <MainLayout
      maxWidth="1440px"
      navbarBg="bg-white"
      mainClassName="bg-neutral-50"
    >
      <div className="pt-16 d-none d-xl-block px-24">
        <BreadCrumb data={breadcrumbsData} />
      </div>

      <div className="d-flex  justify-content-center">
        <div className="max-w-888 w-full p-24 md-p-48">
          {isSubjectLoading ? (
            <LearningSubjectViewLoading />
          ) : (
            <>
              <div className="w-full row gx-0  align-items-center ">
                <div className=" col-12 col-md-6 text-center ">
                  <img
                    src={bookDetailData.imageCover}
                    alt=""
                    className="w-p-100 md-w-328  radius-8"
                  />
                </div>

                <div className="col-12 col-md-6 mt-24 md-mt-0 md-pl-24">
                  <div className="mb-32">
                    <h1 className="h2 md-h1">{bookDetailData.name}</h1>
                    <Divider
                      height="h-2"
                      parentClassName="w-64"
                      lineColor="bg-primary-500"
                    />
                    <p className="text-neutral-300 mt-8">
                      by {bookDetailData.author}
                    </p>
                  </div>
                  <div
                    className="mb-24"
                    style={{
                      visibility:
                      bookDetailData?.name?.toLowerCase() ===
                        "hilo teen design thinking creative marathon 2022"
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    {/* <p className="font-small-medium text-neutral-200">
                      Book Content
                    </p>
                    <div className="d-flex align-items-center mt-8 mb-16">
                      <FiberManualRecordIcon
                        className="fs-12"
                        style={{ color: "#8EA3EE" }}
                      />
                      <p className="font-sm ml-20">
                        {subjectData.countLesson} Lesson
                      </p>
                    </div>
                    <div className="d-flex align-items-center  mb-16">
                      <FiberManualRecordIcon
                        className="fs-12"
                        style={{ color: "#AD8EEE" }}
                      />
                      <p className="font-sm ml-20"> 90 Exercise </p>
                    </div>
                    <div className="d-flex align-items-center  ">
                      <FiberManualRecordIcon
                        className="fs-12"
                        style={{ color: "#DB8EEE" }}
                      />
                      <p className="font-sm ml-20">Certificate Available</p>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* progress */}
              <Can allowAccess="STUDENT">
                <div className="mt-40">
                  <div className="bg-neutral-600 d-md-flex align-items-center md-h-64  px-24 py-16 md-py-0 radius-8">
                    <h6 className="text-white nowrap mr-64">Progress Test</h6>
                    <div className="d-flex w-full align-items-center">
                      <div className="w-full">
                        <ProgressBar
                          bgColor="bg-white"
                          activeColor="info-500"
                          height="4"
                          radius="3"
                          value={
                            (subjectData.certificated?.completeUnit /
                              subjectData.certificated?.totalUnit) *
                            100
                          }
                        />
                      </div>
                      <p className="ml-8 nowrap text-neutral-200 md-mr-24">
                        {subjectData.certificated?.completeUnit || 0}/
                        {subjectData.certificated?.totalUnit || 0} Complete
                      </p>
                    </div>

                    {subjectData.certificated?.isDownload ? (
                      <PDFDownloadLink
                        document={<SerificateDesign data={dataCertif} />}
                        fileName={`${user.name}-${bookDetailData.name}.pdf`}
                        style={{ borderRadius: "8px" }}
                      >
                        <button
                          className={`btn btn-enable nowrap w-full md-w-auto`}
                        >
                          Get Certificate
                        </button>
                      </PDFDownloadLink>
                    ) : (
                      <button
                        disabled={true}
                        className={`btn btn-disable
                        } nowrap w-full md-w-auto`}
                      >
                        Get Certificate
                      </button>
                    )}
                  </div>
                </div>
              </Can>
            </>
          )}

          {/* book content and detail */}
          <div className="mt-40">
            <TabBar data={TabBarData} activeDefault="Self Learning" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LearningSubjectViewOrg;
