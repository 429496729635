import React from "react";
import { ManageAccountOrg } from "../../../components/organism";
const ManageAccount = () => {
  return (
    <>
      <ManageAccountOrg />
    </>
  );
};

export default ManageAccount;
