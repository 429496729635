import React, { useEffect, useState } from "react";
import "./PreviewContentOrg.scss";
import MainLayout from "../../../Layout/Mainlayout";
import imgHeader1 from "../../../../assets/images/detail-preview-bg-1.png";
import imgHeader2 from "../../../../assets/images/detail-preview-bg-2.png";
import LockedContentImage from "../../../../assets/images/locked-content.png";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  DoubleArrowRounded,
  ArrowForward,
  ArrowBack,
} from "@mui/icons-material";
import {
  CodeEditor,
  CompleteParagraph,
  EmbedHtml5,
  EmptySpace,
  Essay,
  FillinTheBlank,
  MatchPairs,
  MultipleChoice,
  OutlineList,
  ShortAnswer,
  SingleChoice,
  TextEditor,
} from "../../../molecules";
import { GET, defConfig } from "../../../../config/RestAPI";
import { PreviewContentLoading } from "../../../molecules";
import { searchNoData } from "../../../../assets/images";
import { Divider } from "../../../atoms";
import { Dropdown } from "react-bootstrap";
import { useClassRoomHero, useGlobalFunction } from "../../../../services";
function PreviewContentOrg() {
  const { id, classId, subjectId, lessonId, topicId } = useParams();

  const [dataSubtopic, setDataSubtopic] = useState();
  const [dataContent, setDataContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [isLocked, setIsLocked] = useState(false);
  const [viewAs, setViewAs] = useState();
  const { getUserInfo } = useGlobalFunction();
  const user = getUserInfo();
  const [showView, setShowView] = useState([user.avatar, user.name, user.id]);
  const backToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  useEffect(() => {
    backToTop();
  }, [id, classId, subjectId, lessonId, topicId]);

  const showContent = (data) => {
    switch (data.contentType.name.toLowerCase()) {
      case "complete a paragraph":
        return (
          <CompleteParagraph
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "fill in the blank":
        return (
          <FillinTheBlank
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "embed html5":
        return (
          <EmbedHtml5
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "essay":
        return (
          <Essay
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "multiple choice":
        return (
          <MultipleChoice
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "short answer":
        return (
          <ShortAnswer
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "single choice":
        return (
          <SingleChoice
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "text editor":
        return (
          <TextEditor
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "code editor":
        return (
          <CodeEditor
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "match pairs":
        return (
          <MatchPairs
            user={user.name}
            view={showView[1]}
            viewid={showView[2]}
            data={data}
          />
        );
      case "empty space":
        return <EmptySpace />;
      default:
        return;
    }
  };

  const initData = async (userid) => {
    setIsLoading(true);

    try {
      const { data } = await GET("/client/classrooms/my_school", defConfig()); //schoolid
      let content = await GET(
        `/client/activity/get_content_book?subTopicId=${id}&classlistId=${classId}&schoolid=${
          data._id
        }&userid=${userid ? userid : user.id}`,
        defConfig()
      );
      setIsLocked(content.isLocked);
      setDataContent(content.data);
      setViewAs(content.userView);
      let subTopic = await GET(`/subtopic/${id}`, defConfig());
      setDataSubtopic(subTopic.data);
      setPagination({
        next: content.next,
        prev: content.prev,
        nextLesson: content.nextLesson,
      });
    } catch (err) {}

    setIsLoading(false);
  };

  const clickView = (val) => {
    setShowView([val.avatar, val.username, val._id]);
    initData(val._id);
  };

  const resetView = () => {
    setShowView([user.avatar, user.name, user.id]);
    initData(user.id);
  };

  useEffect(() => {
    initData();
  }, [id, classId, subjectId, lessonId, topicId]);

  const displayContent = () => {
    if (isLocked) {
      return (
        <div className="h-295">
          <div className="d-flex h-full justify-content-center align-items-center">
            <div className="text-center">
              <img src={LockedContentImage} alt="" className="max-w-143" />
              <h6 className="mt-24">This content under locked. </h6>
              <p className="max-w-297 font-sm">
                Ask your teacher or be patient until the content unlocked!
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (dataContent.length < 1) {
      return (
        <div className="h-295">
          <div className="d-flex h-full justify-content-center align-items-center">
            <div className="text-center">
              <img src={searchNoData} alt="" className="max-w-143" />
              <h6 className="mt-24">No Book Content Yet</h6>
              <p className="max-w-297 font-sm">
                The author hasn't put content on this page yet. Please wait to
                enjoy this page!
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return dataContent.map((data, index) => {
        return (
          <div key={index} className="">
            {showContent(data)}
          </div>
        );
      });
    }
  };

  return (
    <div className="Layout-preview-content">
      <MainLayout
        user={user.name}
        view={showView[1]}
        navbarBg="bg-white"
        navNoMenu
        redirectOnNavClose={`/classroom/start-learning-view/${classId}/${subjectId}`}
        modalTitle="Leave unsubmited exercise"
        modalMessage="Exercises that have not been submitted will be lost. Please submit the exercise before leaving the page"
        title={dataSubtopic?.subject.name}
        footerBg="bg-secondary-200"
      >
        <section className="sub-topic-detail-preview ">
          <img src={imgHeader1} className="image-header1 w-100" alt="" />
          <img src={imgHeader2} className="image-header2 w-100" alt="" />
          <div className="container ">
            {isLoading ? (
              <PreviewContentLoading />
            ) : (
              <div className="row justify-content-center mx-12 md-mx-24">
                <div className="col-lg-8 col-12">
                  <div className="header-preview radius-14 bg-white py-16 px-24 mb-24">
                    <div className="text-neutral-300 mb-8">
                      {dataSubtopic?.topic.name}
                    </div>
                    <h3>{dataSubtopic?.name}</h3>
                    {/* <div
                      className={`${
                        user.role === 5 ? "d-flex" : "d-none"
                      } align-items-md-center flex-column flex-md-row`}
                    >
                      <div className="d-flex align-items-center">
                        <PersonSearchIcon
                          style={{ width: "24px", color: "#7F8894" }}
                        />
                        <div className="ml-8 text-neutral-300">
                          <p className="one-row">View as:</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-md-center w-100">
                        <Dropdown className="ml-md-32 w-100">
                          <Dropdown.Toggle className="bg-view-as w-100 text-start">
                            <div className="d-flex align-items-md-center">
                              <img
                                src={showView[0] || "/images/profile.png"}
                                className="h-24 w-24 mr-8 radius-p-100"
                              />
                              <p className="font-medium">{showView[1]}</p>
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100 dropdown-menu">
                            {viewAs?.map((val, ind) => {
                              return (
                                <Dropdown.Item
                                  onClick={() => clickView(val)}
                                  className="view-as-item w-p-90 d-flex align-items-center"
                                >
                                  <img
                                    src={val?.avatar || "/images/profile.png"}
                                    className="h-24 w-24 mr-8 radius-p-100"
                                  />
                                  {val?.username}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <Link
                          to="#"
                          onClick={() => resetView()}
                          className={`text-neutral-300 pe-auto ${
                            showView[1] === user.name ? "d-none" : "d-flex"
                          }`}
                        >
                          Reset
                        </Link>
                      </div>
                    </div> */}
                  </div>
                  <div className="preview-content  radius-8 ">
                    <div className="content ">
                      <div className="content">{displayContent()}</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-12 mt-96">
                  <div className="row justify-content-center">
                    <div className="col-4 ">
                      {!pagination.prev ? (
                        <div className="d-flex">
                          <button className="btn btn-disable d-flex justify-content-center align-items-center w-28 h-28 p-0 m-0">
                            <ArrowBack className="fs-20" />
                          </button>
                          <div className="ml-8 d-flex justify-content-between flex-column  ">
                            <span className="font-xs text-neutral-300">
                              Back
                            </span>
                            <span className="font-xs text-neutral-300">-</span>
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={`/classroom/content-practice/${classId}/${subjectId}/${lessonId}/${topicId}/${pagination.prev._id}`}
                          className="d-flex"
                        >
                          <button className="btn btn-outline bg-white d-flex justify-content-center align-items-center w-28 h-28 p-0 m-0">
                            <ArrowBack className="fs-20" />
                          </button>
                          <div className="ml-8 d-flex justify-content-between flex-column ">
                            <span className="font-xs text-neutral-300 d-flex">
                              Back
                            </span>
                            <span className="font-xs text-neutral-400 text-start d-flex">
                              {pagination.prev.name.length > 12
                                ? pagination.prev.name.substring(0, 12) + "..."
                                : pagination.prev.name}
                            </span>
                          </div>
                        </Link>
                      )}
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end text-end">
                      {!pagination.next ? (
                        <>
                          <div className="mr-8 d-flex justify-content-between flex-column ">
                            <span className="font-xs text-neutral-300">
                              Next
                            </span>
                            <span className="font-xs text-neutral-400">-</span>
                          </div>
                          <button className="btn btn-disable d-flex justify-content-center align-items-center w-28 h-28 p-0 m-0">
                            <ArrowForward className="fs-20" />
                          </button>
                        </>
                      ) : (
                        <Link
                          to={`/classroom/content-practice/${classId}/${subjectId}/${lessonId}/${topicId}/${pagination.next._id}/`}
                          className="d-flex"
                        >
                          <div className="mr-8 d-flex justify-content-between flex-column ">
                            <span className="font-xs text-neutral-300 text-end d-flex">
                              Next
                            </span>
                            <span className="font-xs text-neutral-400 d-flex">
                              {pagination.next.name.length > 12
                                ? pagination.next.name.substring(0, 12) + "..."
                                : pagination.next.name}
                            </span>
                          </div>
                          <button className="btn btn-outline bg-white d-flex justify-content-center align-items-center w-28 h-28 p-0 m-0">
                            <ArrowForward className="fs-20" />
                          </button>
                        </Link>
                      )}
                    </div>
                    {pagination.nextLesson && (
                      <div className="col-8">
                        <div className="my-16  w-full">
                          <Divider
                            height="h-2"
                            lineColor={"bg-secondary-200"}
                          />
                        </div>
                        <Link
                          to={`/classroom/lesson-preview/${classId}/${subjectId}/${pagination.nextLesson._id}`}
                        >
                          <button className="btn-outline w-full bg-white">
                            Go to Next Lesson
                          </button>
                        </Link>
                      </div>
                    )}
                    {!pagination.nextLesson && !pagination.next && (
                      <div className="col-8">
                        <div className="my-16  w-full">
                          <Divider
                            height="h-2"
                            lineColor={"bg-secondary-200"}
                          />
                        </div>
                        <Link
                          to={`/classroom/start-learning-view/${classId}/${subjectId}`}
                        >
                          <button className="btn-outline w-full bg-white">
                            Back to Content List
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-7 col-12">
                  <div className="preview-footer  d-flex justify-content-center">
                    <div className="pt-98 text-center">
                      {/* <h5
                        className="text-strong cursor-pointer"
                        onClick={backToTop}
                      >
                        <DoubleArrowRounded className="rotate" />
                        <span className="d-block">Back To Top</span>
                      </h5> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </MainLayout>
      <OutlineList
        classId={classId}
        subjectId={subjectId}
        lessonId={lessonId}
        topicId={topicId}
        subtopicId={id}
      />
    </div>
  );
}

export default PreviewContentOrg;
