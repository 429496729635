import React from "react";
import { Form, Formik } from "formik";
import { FormikControl, TableThead } from "../../../atoms";
import { Link } from "react-router-dom";
import { useExportExcel, useManageInformation } from "../../../../services";
import {
  Search,
  ArrowCircleDown,
  ArrowBack,
  ArrowForward,
  FindInPage,
} from "@mui/icons-material";
import "../Table.scss";
import GradeCard from "../../Cards/GradeCard";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

function TableTeacherManageInformation() {
  const {
    isLoading,
    isLoadingTable,
    dataGradesInformation,
    tableOption,
    dataHeader,
    sortirHeader,
    initialValuesTableOption,
    onSubmitTableOption,
    handlePageClick,
    onReset,
    formik,
    pagination,
    dataExcel,
    csvDataName,
  } = useManageInformation();
  const { exportToExcel } = useExportExcel();

  return (
    <>
      {!isLoading ? (
        <>
          <div className="cards-grade-all mb-24">
            <div className="row">
              <GradeCard tableOption={tableOption} />
            </div>
          </div>
          <div className="top-table px-24 bg-secondary-300">
            <Formik
              initialValues={initialValuesTableOption}
              onSubmit={onSubmitTableOption}
              enableReinitialize={true}
            >
              <div className="w-full">
                <Form className="d-flex justify-content-between gap-md-5 flex-column flex-md-row">
                  <div className="d-flex gap-3 w-full flex-column flex-md-row">
                    <div className="w-100">
                      <FormikControl
                        size="sm"
                        control="input"
                        name="keyword_student"
                        placeholder="Search student here"
                        icon={<Search className="text-neutral-200 fs-14" />}
                      />
                    </div>
                    <div className="w-100">
                      <FormikControl
                        size="sm"
                        control="input"
                        name="keyword_lesson"
                        placeholder="Search lesson here"
                        icon={<Search className="text-neutral-200 fs-14" />}
                      />
                    </div>
                    <div className="w-100">
                      <FormikControl
                        size="sm"
                        control="input"
                        name="keyword_assessment"
                        placeholder="Search assessment here"
                        icon={<Search className="text-neutral-200 fs-14" />}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-md-end">
                    <button
                      type="submit"
                      className="btn btn-primary border-1 p-[4px] bg-warning font-sm text-neutral-500 mr-16"
                    >
                      Apply
                    </button>
                    <button
                      type="reset"
                      className="btn btn-outline font-sm text-neutral-500 mr-16"
                      onClick={() => onReset()}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-outline bg-white fs-14 text-neutral-500 d-flex align-items-center"
                      onClick={() =>
                        exportToExcel([dataExcel], csvDataName, "Grade-Oveview")
                      }
                    >
                      <ArrowCircleDown className="text-neutral-500 fs-18 mr-6" />{" "}
                      ExportTable
                    </button>
                  </div>
                </Form>
              </div>
            </Formik>
          </div>
          <div className="show-datatable">
            <div className="table-responsive">
              <table className="table-report">
                <thead>
                  <tr>
                    {dataHeader.map((item, index) => {
                      if (item.status) {
                        return (
                          <th key={index} width={item.width}>
                            <TableThead
                              title={item.title}
                              placeholder={item.placeholder}
                              sortir={item.sortir}
                              isSorted={item.isSorted}
                              isSortedDesc={item.isSortedDesc}
                              onClick={() => {
                                sortirHeader(index);
                              }}
                            />
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dataGradesInformation ? (
                    dataGradesInformation.length > 0 ? (
                      dataGradesInformation?.map((item, index) => {
                        const nameStudent = item.student?.name
                          .toLowerCase()
                          .split(" ");
                        for (let i = 0; i < nameStudent.length; i++) {
                          nameStudent[i] =
                            nameStudent[i][0].toUpperCase() +
                            nameStudent[i].substr(1) +
                            " ";
                        }
                        nameStudent.join(" ");
                        return (
                          <tr key={index}>
                            <td>{nameStudent}</td>
                            <td>{item.lesson && item.lesson.name}</td>
                            <td>
                              { item.subtopic && item.subtopic.name } <br />
                              { item.assessment && moment(item.assessment.timneline).format("Y-MM-DD LT") }
                            </td>
                            <td>
                              {parseFloat(item.assessment.score) >= 0
                                ? parseFloat(item.assessment.score).toFixed(1)
                                : "N/A"}
                            </td>
                            <td>
                              <Link to={`detail/${item.assessment._id}`}>
                                <FindInPage className="text-neutral-300 fs-18" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          className="datanotfound text-center font-sm text-neutral-200"
                          colSpan="5"
                        >
                          Loading data...
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td
                        className="datanotfound text-center font-sm text-neutral-200"
                        colSpan="5"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="navigation-table">
              <div className="font-sm text-neutral-300 d-none d-md-block">
                {pagination.current_page} of {pagination.total_page}
              </div>
              <div className="pagination-table">
                <div className="font-sm text-neutral-300 your-page d-flex align-items-center">
                  You're in page
                  <form onSubmit={formik.handleSubmit}>
                    <div className="w-48 ml-16">
                      <div className="form-input text-start">
                        <div className="input-area h-32 font-xs bg-white">
                          <input
                            type="number"
                            id="topage"
                            name="topage"
                            className="w-full input-control radius-8 py-8 pl-16 pr-16 font-xs"
                            onChange={formik.handleChange}
                            value={formik.values.topage}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="divider-nav"></div>
                <div style={{ marginRight: "24px" }}>
                  {pagination.prev_page ? (
                    <button
                      className="btn-paginate"
                      onClick={() =>
                        handlePageClick(pagination.current_page - 1)
                      }
                    >
                      <ArrowBack style={{ fontSize: "16px" }} />
                    </button>
                  ) : (
                    <button className="btn-paginate btn-disable" disabled>
                      <ArrowBack style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
                <div>
                  {pagination.next_page ? (
                    <button
                      className="btn-paginate"
                      onClick={() =>
                        handlePageClick(pagination.current_page + 1)
                      }
                    >
                      <ArrowForward style={{ fontSize: "16px" }} />
                    </button>
                  ) : (
                    <button className="btn-paginate btn-disable" disabled>
                      <ArrowForward style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Skeleton count={2} height={50} />
      )}
    </>
  );
}

export default TableTeacherManageInformation;
