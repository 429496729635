import React from "react";
import MainLayout from "../../../Layout/Mainlayout";
import {
  OverviewStudentDashboard,
  WelcomeStudentDashboard,
  UpcomingClassStudentDashboard,
  GradeStudentDashboard,
  MyBookListStudentDashboard,
  ContinueClassStudentDashboard,
  BoxDashboardCard,
  ModalAnnouncement,
} from "../../../molecules";
import { 
  SwitchGames, 
  CreateSubjectBg,
  GradeBg,
} from "../../../../assets/images";
import { useStudentDashboard } from "../../../../services";
const StudentDashboard = () => {
  const {
    dataBookList,
    isLoadingBookList,
    continueClassData,
    continueClassLoading,
    upcomingData,
    upcomingLoading,
    overviewData,
    overviewLoading,
  } = useStudentDashboard();
  return (
    <>
      <MainLayout maxWidth="1440px" navbarBg="bg-white">
        <div className="mt-32">
          <ModalAnnouncement />
          <div className="mb-5"></div>
          <div className="px-24 md-px-48">
            <div className="row ">
              <div className="col-12 col-xl-3">
                <div className="row ">
                  <div className="col-12 col-md-6 col-xl-12">
                    <WelcomeStudentDashboard />
                  </div>
                  <div className=" col-6  col-xl-12 xl-mt-24 d-none">
                    <OverviewStudentDashboard
                      data={overviewData}
                      isLoading={overviewLoading}
                    />
                  </div>
                  <div className=" col-12 d-none d-xl-block   mt-24">
                    <GradeStudentDashboard />
                  </div>
                </div>
              </div>
              <div className="col-12  col-xl-4 mt-16 md-mt-24 xl-mt-0">
                <UpcomingClassStudentDashboard
                  data={upcomingData}
                  isLoading={upcomingLoading}
                />
              </div>
              <div
                className="col-12  col-xl-5 d-flex flex-column justify-content-md-between mt-16 xl-mt-0"
                style={{ gap: "16px" }}
              >
                <div className="row xl-h-p-48 ">
                  <div className="col-12 h-155 md-h-255 mb-16">
                    <ContinueClassStudentDashboard
                      data={continueClassData}
                      isLoading={continueClassLoading}
                    />
                  </div>
                  <div className="col-12 col-md-4 h-120 xl-h-173">
                    <BoxDashboardCard
                      bgImage={GradeBg}
                      bgColor="#EAF7FA"
                      isToExternal
                      title={
                        <span>
                          Atlaz <br /> Teach
                        </span>
                      }
                      link="https://teach.hiatlaz.com"
                    />
                  </div>
                  <div className="col-12 col-md-4 h-120 xl-h-173 mt-24 md-mt-0">
                    <BoxDashboardCard
                      bgImage={CreateSubjectBg}
                      bgColor="#F9F2EC"
                      isToExternal
                      title={
                        <span>
                          English <br /> Test
                        </span>
                      }
                      link="https://test.hiatlaz.com"
                    />
                  </div>
                  <div className="col-12 col-md-4 h-120 xl-h-173 mt-24 md-mt-0 ">
                    <BoxDashboardCard
                      bgImage={SwitchGames}
                      bgColor="#EAEDFB"
                      link="/games"
                      // isToExternal
                      // isCommingSoon
                      title={
                        <span>
                          Atlaz <br /> Game
                        </span>
                      }
                    />
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-12 ">
                    <MyBookListStudentDashboard
                      data={dataBookList}
                      isLoading={isLoadingBookList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default StudentDashboard;
