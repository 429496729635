import React from "react";
import { Register4Org } from "../../../components/organism";

const Register4 = () => {
  return (
    <>
      <Register4Org />
    </>
  );
};

export default Register4;
