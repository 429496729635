import { Font, StyleSheet } from "@react-pdf/renderer";
import nunito from "./assets/font/NunitoRegular.ttf";
import ubuntu from "./assets/font/Ubuntu-Medium.ttf";
import ubuntuBold from "./assets/font/Ubuntu-Bold.ttf";
import nunitoItal from "./assets/font/Nunito-SemiBoldItalic.ttf";
import nunitoItalic from "./assets/font/Nunito-Italic.ttf";
import nunitoBold from "./assets/font/Nunito-Bold700.ttf";
import nunitoSemibold from "./assets/font/Nunito-SemiBold.ttf";
Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: ubuntu,
    },
    {
      src: ubuntuBold,
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: "Nunito",
  fonts: [
    {
      src: nunito,
    },
    { src: nunitoItalic, fontStyle: "italic" },
    { src: nunitoSemibold, fontWeight: 600 },
    { src: nunitoBold, fontWeight: 700 },
  ],
});

Font.register({
  family: "NunItal",
  src: nunitoItal,
});

export default StyleSheet.create({
  flex: {
    display: "flex",
  },
  fontUbuntu: {
    fontFamily: "Ubuntu",
  },
  fontNunito: {
    fontFamily: "Nunito",
  },

  italic: {
    fontStyle: "italic",
  },
  masterH: {
    fontFamily: "Ubuntu",
    fontSize: "48px",
    fontWeight: 700,
    color: "#2B5D80",
  },
  ptext: {
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: "16px",
    color: "#7F8894",
  },
  h1: {
    marginVertical: "4px",
    fontFamily: "Ubuntu",
    fontWeight: 700,
    fontSize: "36px",
    color: "#397CAB",
  },
  h2: {
    fontFamily: "Ubuntu",
    fontSize: "20px",
    fontWeight: 700,
    color: "#397CAB",
  },
  plittle: {
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: "12px",
    color: "#7F8894",
  },
  block: {
    marginTop: "4px",
    width: "178px",
    height: "26px",
    borderRadius: "5px",
    backgroundColor: "#FEE5B5",
    border: "1.6px",
    borderBottom: "4px",
    borderColor: "#FDBF48",
    textAlign: "center",
  },
  h3: {
    fontFamily: "Ubuntu",
    fontSize: "16px",
    fontWeight: 700,
    color: "#2A384C",
  },
  date: {
    marginTop: "85px",
    fontFamily: "Ubuntu",
    fontWeight: 500,
    fontSize: "12px",
    color: "#2B5D80",
  },
  sign: {
    fontFamily: "NunItal",
    fontSize: "8px",
    color: "#7F8894",
  },
  superPlay: {
    width: "329px",
    height: "343px",
  },
  superEscal: {
    width: "303px",
    height: "283px",
  },
});
