import React from "react";
import { LessonPreviewMyBookListOrg } from "../../../components/organism";
const LessonPreviewMyBookList = () => {
  return (
    <>
      <LessonPreviewMyBookListOrg />
    </>
  );
};

export default LessonPreviewMyBookList;
