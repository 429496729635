import React from "react";
import { SubjectPostDetailOrg } from "../../../components/organism";

const SubjectPostDetail = () => {
  return (
    <>
      <SubjectPostDetailOrg />
    </>
  );
};

export default SubjectPostDetail;
