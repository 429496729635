import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET } from "../../../config/RestAPI";
import { useGlobalFunction } from "../../../services";

const Logout = () => {
  const disPatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    localStorage.removeItem("redirect");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/accessToken");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/refreshToken");
    localStorage.removeItem(process.env.REACT_APP_BASE_URL + "/deviceToken");
    disPatch({ type: "SET_AUTH", value: { ...auth, isLogin: false } });
    navigate("/");
  });
  return "";
};

export default Logout;
