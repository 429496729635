import React from "react";
import { ClassRoomSubjectOrg } from "../../../components/organism";

const ClassRoomSubject = () => {
  return (
    <>
      <ClassRoomSubjectOrg />
    </>
  );
};

export default ClassRoomSubject;
