import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { ModalLink, ModalTrigger } from "../../atoms";
const MainHeaderNoMenu = (props) => {
  return (
    <>
      <ModalLink id="navBack" />
      <nav
        className={`${
          props.user === props.view
            ? "h-64 justify-content-center"
            : "h-90 justify-content-around"
        } d-flex flex-column bg-white position-fixed w-full`}
        style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)", zIndex: "9" }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div className="w-full pl-24 md-pl-48 d-flex align-items-center">
            <img src="/images/logo.png" className="d-none d-md-block" />
            <img src="/images/logo-icon.png" className="d-md-none h-32 w-32" />
            {props.title && (
              <h5 className="ml-16 pl-16 border-start border-secondary-200">
                {props.title}
              </h5>
            )}
          </div>
          {props.isNeedConfirm ? (
            <ModalTrigger
              target="navBack"
              className="pr-24 md-pr-48"
              data={{
                redirect: props.redirectOnClose,
                message: props.modalMessage,
                title: props.modalTitle,
              }}
            >
              <CloseIcon />
            </ModalTrigger>
          ) : (
            <Link to={props.redirectOnClose} className="pr-24 md-pr-48">
              <CloseIcon />
            </Link>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center ">
          <div
            className={`${
              props.user === props.view ? "d-none" : "d-block"
            } d-flex justify-content-center bg-info-100 w-100 mx-24 md-mx-48 rounded-2`}
          >
            <p>Viewing as: </p> <p className="font-bold ml-8">{props.view}</p>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainHeaderNoMenu;
