import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";

const RedirectIfAuthenticated = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let refreshToken = localStorage.getItem(
      process.env.REACT_APP_BASE_URL + "/refreshToken"
    );
    if (refreshToken) {
      let decodedToken = null;
      try {
        // check apakah refreshtToken valid
        decodedToken = jwt_decode(refreshToken);

        if (moment().unix() * 1000 < decodedToken.exp * 1000) {
          // ===========LOGIN BERHASIL===============
          // jika refreshToken belum expired login berhasil dan redirect ke halaman tujuan (redirect params)
          navigate("/dashboard");
        } else {
          navigate("/logout");
        }
      } catch (error) {
        // kalo refreshToken gabisa di decode maka redirect ke halaman login
        navigate(`/login`);
      }
    } else {
      navigate(`/login`);
    }
  }, []);

  return props.children;
};

export default RedirectIfAuthenticated;
