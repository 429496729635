import React from "react";

const EmptySpace = () => {
  return (
    <div className="col-12 card-container  ">
      <div style={{ height: "32px" }}></div>
    </div>
  );
};

export default EmptySpace;