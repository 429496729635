import React, { useState } from "react";
import { ProductDetailOrg } from "../../components/organism";

const ProductDetail = () => {
  return (
    <>
      <ProductDetailOrg />
    </>
  );
};

export default ProductDetail;
