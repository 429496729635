import React from "react";
import { WelcomeAssessmentOrg } from "../../../components/organism";
const WelcomeAssessment = () => {
  return (
    <>
      <WelcomeAssessmentOrg />
    </>
  );
};

export default WelcomeAssessment;
