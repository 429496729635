import React from "react";
import { TeacherResourceOrg } from "../../components/organism";

const TeacherResource = () => {
  return (
    <>
      <TeacherResourceOrg />
    </>
  );
};

export default TeacherResource;
