import React from "react";
import { ClassRoomOrg } from "../../components/organism";

const Classroom = () => {
  return (
    <>
      <ClassRoomOrg />
    </>
  );
};

export default Classroom;
