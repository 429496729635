import React from "react";
import { Register1Org } from "../../../components/organism";

const Register1 = () => {
  return (
    <>
      <Register1Org />
    </>
  );
};

export default Register1;
