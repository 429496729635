import React from "react";
import { Register5Org } from "../../../components/organism";

const Register5 = () => {
  return (
    <>
      <Register5Org />
    </>
  );
};

export default Register5;
