import React, { useEffect, useCallback } from "react";
import MainLayout from "../../Layout/Mainlayout";
import { useGames } from "../../../services";
import { BreadCrumb } from "../../atoms";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "./index.scss";
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined";
const GamesOrg = () => {
  const { user, breadcrumbsData, styleContainer, styleIframe } = useGames();

  const handle = useFullScreenHandle();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <MainLayout navbarBg="secondary-100">
        <div className="px-24 md-px-48">
          <div className="pt-16 d-none d-xl-block">
            <BreadCrumb data={breadcrumbsData} />
          </div>

          <div className="mx-auto games-container">
            <div className="mb-20 d-flex justify-content-between">
              <div>
                <p className="h5 xl-h4 ">Games - Penguin Can Fly</p>
                <div className="rectangle w-64 bg-primary-500 h-2 "></div>
              </div>
              {/* <div>
                <button
                  className="btn btn-primary font-sm d-flex align-items-center "
                  onClick={handle.enter}
                  style={{ gap: "8px", fontWeight: "600" }}
                >
                  <span>Fullscreen </span>
                  <FitScreenOutlinedIcon />
                </button>
              </div> */}
            </div>
            <div style={styleContainer} className="mb-16">
              {/* <FullScreen handle={handle}> */}
                <iframe
                  allowFullScreen="true"
                  style={styleIframe}
                  src={`https://penguin.hiatlaz.com?idUser=${user.id}`}
                ></iframe>
              {/* </FullScreen> */}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default GamesOrg;
