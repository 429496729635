import React from "react";
import { Register2Org } from "../../../components/organism";

const Register2 = () => {
  return (
    <>
      <Register2Org />
    </>
  );
};

export default Register2;
