import React from "react";
import { LessonPreviewOrg } from "../../../components/organism";

const index = () => {
  return (
    <>
      <LessonPreviewOrg />
    </>
  );
};

export default index;
